import React from 'react'
import axios from 'axios'
import countries from '../assets/jsons/countries.json'

export const CountryContext = React.createContext()

export default function CountryProvider({children}) {
  const [country, setCountry] = React.useState(null)

  React.useEffect(() => {
    // We get the country through the Cloudflare API, find its name in our JSON and then, we get its flag
    axios.get('https://www.cloudflare.com/cdn-cgi/trace').then(r => {
      const countryCode = r.data.split('\n')[8].split('=')[1]
      const countryName = countries.find(({iso}) => iso === countryCode)?.name
      if (countryName) {
        setCountry({country: countryName})
      } else if (countryCode === 'T1') {
        setCountry({country: 'Navegador Tor'})
      } else {
        // If no country found (Tor Network, for example), we set an unknown value
        setCountry({country: 'Desconocido'})
      }
    })
  }, [])

  return (
    <CountryContext.Provider value={country}>
      {children}
    </CountryContext.Provider>
  )
}
