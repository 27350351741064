module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://8d304f7144164429beb8557c69b00a6c@o419110.ingest.sentry.io/5328120","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-163831012-1","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Psiconfort: Consultas y Orientación Psicológica Online","description":"La distancia ya no es una barrera. ¡ Te acompaño en tu proceso psicológico! Inicia tu terapia 100% online de la mano de Psiconfort y desde cualquier país del mundo.","short_name":"Psiconfort","start_url":"/","background_color":"#EFEBEB","theme_color":"#EFEBEB","display":"standalone","icon":"src/images/favicon/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e40ca51b98bf152e2a412317e49cfe4e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
